.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: #fff;
    padding: 1rem;
    flex-wrap: wrap; /* Allow wrapping */
  }
  
  .header h1 {
    margin: 0;
    flex: 1; /* Allow the header title to take up available space */
  }
  
  .header nav ul {
    list-style: none;
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0;
    flex: 2; /* Allow the nav to take up available space */
    justify-content: flex-end; /* Align nav items to the end */
  }
  
  .header nav ul li {
    margin: 0;
  }
  
  .header nav ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .header nav ul li a:hover {
    color: #ff6347;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .header {
      flex-direction: column; /* Stack elements vertically */
      align-items: flex-start; /* Align elements to the start */
    }
  
    .header nav ul {
      justify-content: flex-start; /* Align nav items to the start */
      flex-direction: column; /* Stack nav items vertically */
    }
  
    .header nav ul li {
      margin: 0.5rem 0; /* Add margin between nav items */
    }
  }
  