.home {
    text-align: center;
    padding: 2rem;
    background-color: #f4f4f4;
  }
  
  .home h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .home p {
    font-size: 1.25rem;
  }
  