.product-item {
    background: #fff;
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 4px;
    width: calc(33.333% - 1rem);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .product-item:hover {
    transform: scale(1.02);
  }
  
  .product-item img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .product-item h3 {
    margin: 0.5rem 0;
    font-size: 1.25rem;
  }
  
  .product-item p {
    margin: 0.5rem 0;
    font-size: 1rem;
    color: #555;
  }
  
  .product-item button {
    padding: 0.5rem 1rem;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .product-item button:hover {
    background-color: #ff6347;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .product-item {
      width: calc(100% - 1rem);
    }
  }
  